<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <el-select v-model="query.warehouseType" filterable clearable placeholder="库房类型" class="filter-item" style="width: 150px" @change="toQuery">
        <el-option v-for="(value,key) in warehouseTypes" :key="key" :label="value" :value="key" />
      </el-select>
      <el-input v-model="query.code" clearable placeholder="仓库编码" class="filter-item" style="width: 150px" @keyup.enter.native="toQuery" />
      <el-input v-model="query.name" clearable placeholder="输入库房名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <eForm ref="form" :is-add="isAdd" />
    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="code" label="编码" min-width="80" />
      <el-table-column prop="erpId" label="ERP编码" min-width="80" v-if="$erp.enable()" />
      <el-table-column prop="name" label="名称" min-width="160" />
      <el-table-column prop="address" label="地址" min-width="240" show-overflow-tooltip />
      <el-table-column prop="info" label="详细信息" min-width="240" show-overflow-tooltip />
      <el-table-column prop="warehouseType" label="仓库类型" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{warehouseTypes[scope.row.warehouseType]}}</span>
        </template>
      </el-table-column>
      <el-table-column width="100px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import initDict from "@/mixins/initDict";
import { edit, del } from "@/api/warehouse";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData, initDict],
  data() {
    return {
      delLoading: false,
      warehouseTypes: ["中央库房", "零售店"],
      query: {
        warehouseType: null,
        code: null,
        name: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/warehouse";
      const sort = "id,desc";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    add() {
      this.isAdd = true;
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.isAdd = false;
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)));
    },
  },
};
</script>